<template>
  <window-content v-if="!preventBind" class="financeiro-window">
    <div class="w-m-header">
      <div>
        <h2 v-if="model.id">Campo: {{ model.name }}</h2>
        <h2 v-else>Novo Campo</h2>
      </div>
      <button @click="$uloc.window.close($root.wid)" type="button" class="btn-close">
        <i class="fal fa-times"/>
        <u-tooltip content-class="bg-indigo" :offset="[5, 5]">Fechar</u-tooltip>
      </button>
    </div>
    <div class="w-m-body e-input-modern label-size-2 size1 fin-form">
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Detalhes do campo</legend>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 100px; min-height: 32px">
            <erp-s-field view="ll" :label="!model.required ? 'Não obrigatório' : 'Obrigatório'">
              <sl-checkbox color="red" no-yes-no-val v-model="model.required" id="active"/>
            </erp-s-field>
          </e-col>
          <e-col class="flex items-center justify-end" style="max-width: 120px; min-height: 32px">
            <erp-s-field view="ll" :label="!model.active ? 'Desativado' : 'Ativo'">
              <sl-checkbox no-yes-no-val v-model="model.active" id="active"/>
            </erp-s-field>
          </e-col>
          <!--          <e-col class="flex items-center justify-end" style="max-width: 120px; min-height: 32px">
                      <erp-s-field view="ll" label="Público"
                                   icon-help="Marcar como público libera o acesso ao projeto para qualquer pessoa">
                        <sl-checkbox no-yes-no-val v-model="model.public" id="active"/>
                      </erp-s-field>
                    </e-col>-->
        </e-row>
        <e-row>
          <e-col>
            <e-row>
              <e-col>
                <e-row mr>
                  <e-col style="max-width: 400px; width: 400px; min-width: 400px">
                    <erp-s-field label="Tipo do Campo">
                      <form-field-type-select v-model="model.type" />
                    </erp-s-field>
                  </e-col>
                  <e-col>
                    <erp-s-field label="Ordem / Posição">
                      <erp-input placeholder="Posição do campo" type="number" v-model="model.order"/>
                    </erp-s-field>
                  </e-col>
                </e-row>
                <e-row mr>
                  <e-col>
                    <erp-s-field required label="Nome" icon-help="Nome do campo que será apresentado">
                      <erp-input placeholder="Defina um nome para o campo" v-model="model.name" required />
                    </erp-s-field>
                  </e-col>
                  <e-col style="max-width: 150px">
                    <erp-s-field label="Código Único" icon-help="Será utilizado para capturar este campo nos relatórios">
                      <erp-input placeholder="Digite um código" v-model="model.code" />
                    </erp-s-field>
                  </e-col>
                </e-row>
              </e-col>
              <e-col v-if="model && model.type">
                <div style="margin-left: 80px; padding: 40px; text-align: left; border: #cccccc 1px dotted; min-height: 180px">
                  <div class="font-thin">Exemplo:<br><br></div>
                  <detect-input-v2 :field="model" :response="responseFake" v-model="fakeInput" />
                </div>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Tópico">
                  <form-topic-select v-model="model.topic" :form="form" />
                </erp-s-field>
              </e-col>
            </e-row>
            <e-row mr>
              <e-col>
                <erp-s-field label="Descrição">
                  <textarea placeholder="Descrição sobre o campo" v-model="model.description" class="sl-textarea"
                            rows="3"/>
                </erp-s-field>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
            <legend class="legend-simple">Definições do Campo</legend>
          </e-col>
        </e-row>
        <e-row v-if="isFieldWithValues">
          <e-col>
            <erp-s-field label="Valores pré-definidos do campo, um por linha">
              <textarea placeholder="Lembre-se de digitar um valor por linha" class="sl-textarea" rows="10" v-model="model.config.optionValues" />
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row>
          <e-col style="max-width: 200px">
            <erp-s-field view="ll" label="Título em negrito">
              <sl-checkbox v-model="model.config.labelBold" id="active"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px">
            <erp-s-field view="ll" label="Campo em negrito">
              <sl-checkbox v-model="model.config.inputBold" id="active"/>
            </erp-s-field>
          </e-col>
          <e-col style="max-width: 200px">
            <erp-s-field view="ll" label="Somente leitura" icon-help="Não permite preencher o campo, somente visualizá-lo">
              <sl-checkbox v-model="model.config.readonly" id="active"/>
            </erp-s-field>
          </e-col>
          <e-col class="text-right" style="display: flex; justify-content: flex-end">
            <div style="max-width: 200px;">
              <erp-s-field view="ll" label="Largura" icon-help="Somente para textarea">
                <erp-input v-model="model.config.width" placeholder="100px ou 100%" />
              </erp-s-field>
            </div>
          </e-col>
          <e-col v-if="type === 'largetext'" class="text-right" style="display: flex; justify-content: flex-end">
            <div style="max-width: 200px;">
              <erp-s-field view="ll" label="Altura" icon-help="Somente para textarea">
                <erp-input v-model="model.config.height" type="number" placeholder="somente número (pixels)" />
              </erp-s-field>
            </div>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Texto de ajuda" icon-help="Ficará como este exemplo">
              <erp-input placeholder="" v-model="model.config.tip"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr>
          <e-col>
            <erp-s-field label="Placeholder (Texto de fundo)">
              <erp-input v-model="model.config.placeholder"/>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Posição do título do campo">
              <select class="sl-input input-sm" v-model="model.config.labelPosition">
                <option v-for="(v, key) in [
                  {label: 'Em cima à esquerda', value: 'tl'},
                  {label: 'Em cima à direita', value: 'tr'},
                  {label: 'Ao lado à esquerda', value: 'll'}
              ]" :key="'val-' + key" :value="v.value">{{v.label}}</option>
              </select>
            </erp-s-field>
          </e-col>
          <e-col>
            <erp-s-field label="Layout do campo">
              <select class="sl-input input-sm" v-model="model.config.layout">
                <option v-for="(v, key) in [
                  {label: 'Ajustar ao layout', value: 'inline'},
                  {label: 'Nova linha', value: 'newline'}
              ]" :key="'val-' + key" :value="v.value">{{v.label}}</option>
              </select>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row v-if="model.config.layout === 'newline'" mr>
          <e-col>
            <erp-s-field label="Título da Seção" icon-help="Não obrigatório, mas se preenchido, insere um título antes do campo. Útil para separação de seções em um formulário.">
              <erp-input v-model="model.config.pretitle"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr class="m-t-sm">
          <e-col>
            <erp-s-field label="Valor Padrão" icon-help="Ficará como este exemplo">
              <erp-input placeholder="Informe um valor padrão que já virá preenchido" v-model="model.config.defaultValue"/>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
      <div class="body-content">
        <e-row class="prelegend">
          <e-col class="flex content-end">
          <legend class="legend-simple">Configurações Avançadas</legend>
          </e-col>
        </e-row>
        <e-row mr class="m-t-sm">
          <e-col>
            <erp-s-field label="Integração com Campos Internos Padrão (Avançado)" icon-help="Somente use se tiver conhecimento sobre as configurações.">
              <erp-input placeholder="Somente use se tiver conhecimento sobre as configurações" v-model="model.config.bind"/>
            </erp-s-field>
          </e-col>
        </e-row>
        <e-row mr class="m-t-sm">
          <e-col>
            <erp-s-field label="Regras Condicionais" icon-help="Somente use se tiver conhecimento sobre as configurações.">
              <textarea class="sl-textarea" placeholder="Somente use se tiver conhecimento sobre as configurações" v-model="model.config.conditions"/>
            </erp-s-field>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right footer-modern window-actions">
      <div class="text-left">
        <u-btn @click="$uloc.window.close($root.wid)" label="Voltar"
               class="bg-blue-grey-1 text-blue" no-caps/>
      </div>
      <div class="text-right">
        <u-btn :loading="loading" type="button" @click="save('copy')" label="Salvar Como Cópia" no-caps
               :color="'white'" flat size="sm"
               class="b-radius-3px text-black m-r-lg"/>
        <u-btn :loading="loading" type="button" @click="save('andClose')" label="Salvar Formulário" no-caps
               :color="'green'"
               class="b-radius-3px no-border-radius-right__"/>
        <!--        <u-btn icon="sort-down" icon-type="fa" no-caps class="p-btn-down normal b-radius-3px font-12 text-white">
                  <u-popover ref="popover" class="window-context-menu" style="width: 192px" anchor="bottom right"
                             self="top right" :offset="[0, 0]">
                    <div>
                      <ul>
                        <menu-options-item @click="save('andEdit')" label="Salvar e continuar editando" close/>
                        <menu-options-item @click="save('andNew')" label="Salvar e criar novo" close/>
                      </ul>
                    </div>
                  </u-popover>
                </u-btn>-->
      </div>
    </div>
  </window-content>
</template>

<script>
import {UTooltip, UPopover, debounce} from "uloc-vue"
import ERow from '@/components/layout/components/Row'
import {ErpInput, ErpSField, ErpSelect, WindowContent} from 'uloc-vue-plugin-erp'
import ECol from '@/components/layout/components/Col'

import {newFieldForm, updateFieldForm, findField} from "@/domain/form/services/field"
import mock from "@/domain/form/helpers/fieldModel"
import SlCheckbox from "@/reuse/input/Checkbox"
import FormFieldTypeSelect from "components/forms/components/include/FieldTypeSelect"
import FormTopicSelect from "components/forms/components/include/TopicSelect"
import DetectInputV2 from "components/forms/input/dynamic/DetectInput"

export default {
  name: 'CampoWindow',
  provide: function () {
    return {
      container: this,
      form: this
    }
  },
  mixins: [],
  directives: {},
  components: {
    DetectInputV2,
    FormTopicSelect,
    FormFieldTypeSelect,
    // ErpSelect,
    // PersonSelect,
    SlCheckbox,
    // DatetimeInput,
    ErpInput,
    ErpSField,
    ERow,
    ECol,
    // ErpSelect,
    UTooltip,
    //MenuOptionsItem,
    // UPopover,
    WindowContent
  },
  props: ['id', 'form', 'options', 'router'],
  data() {
    const model = JSON.parse(JSON.stringify(mock))
    model.form = this.form.id
    return {
      loading: false,
      editing: false,
      success: false,
      status: '',
      preventBind: true,
      fakeInput: null,
      responseFake: {
        valor: null
      },
      model: model
    }
  },
  mounted() {
    if (this.id) {
      this.load()
    } else {
      this.preventBind = false
    }
  },
  watch: {},
  computed: {
    isFieldWithValues () {
      const type = this.model.type && this.model.type.id ? this.model.type.id : this.model.type
      return ['select', 'option'].includes(type)
    },
    type () {
      return this.model.type && this.model.type.id ? this.model.type.id : this.model.type
    }
  },
  methods: {
    load() {
      this.loading = true
      findField(this.model.id || this.id)
          .then(response => {
            console.log(response)
            if (response.data.id) {
              this.model.id = response.data.id
            }
            this.preventBind = true
            this.model = Object.assign({}, this.model, this.prepareToEdit(response.data))
            if (Array.isArray(this.model.config) || !this.model.config) {
              this.model.config = JSON.parse(JSON.stringify(mock.config))
            }
            this.$nextTick(() => {
              setTimeout(() => {
                this.preventBind = false
              }, 1)
            })
            console.log(this.model)
            this.loading = false
          })
          .catch(error => {
            this.alertApiError(error)
            this.loading = false
          })
    },
    prepareToSave() {
      const data = JSON.parse(JSON.stringify(this.model))

      console.log('XXX', data)

      const relacoes = ['type', 'form', 'topic']
      relacoes.map(key => {
        if (data[key] && data[key].id) {
          data[key] = data[key].id
        }
      })

      data.config = JSON.stringify(data.config)

      return data
    },
    prepareToEdit(data) {
      /*const valores = ['valor', 'valorPago', 'descontos', 'jurosMulta']
      valores.map(key => {
        data[key] = this.$options.filters.moeda(data[key])
        if (!data[key]) {
          data[key] = null
        }
      })*/
      /*const datas = ['dataCompetencia', 'dataVencimento', 'dataPagamento']
      datas.map(key => {
        if (data[key] && data[key].date) {
          data[key] = this.$options.filters.formatDate(data[key].date)
        }
      })*/
      if (data.type) {
        let t = data.type
        data.type = null
        this.$nextTick(() => {
          setTimeout(() => {
            this.model.type = t
          }, 10)
        })
      }
      console.log('ZZZ', data)
      return data
    },
    async save(type = 'andClose') {
      this.loading = true
      let data
      try {
        data = this.prepareToSave()
      } catch (e) {
        console.log(e)
        this.alertApiError(null, 'Erro de validação', null, `${e.message} Código L-${e.lineNumber}`)
        this.loading = false
        return
      }
      const submit = () => {
        let method = newFieldForm
        if (this.model.id && type !== 'copy') {
          method = updateFieldForm
        }
        method(data)
            .then(response => {
              this.loading = false
              console.log(response)
              const isEditing = this.isEditing
              this.model.id = response.data.id
              // this.$uloc.window.close(this.$root.wid)
              if (this.id) {
                this.$uloc.window.emit(this.$root.wid, 'updated', response.data)
                this.success = true
                this.dg()
                this.$uloc.notify({
                  color: 'positive',
                  message: `Campo com ID #${response.data.id} alterado.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              } else {
                this.$uloc.window.emit(this.$root.wid, 'created', response.data)
                this.$uloc.notify({
                  color: 'black',
                  message: `Novo Campo com ID #${response.data.id} ${this.id ? 'modificado' : 'criado'}.`,
                  position: 'bottom-left',
                  /*actions: [{
                    label: 'Visualizar',
                    handler: () => {
                      this.gotoPerson(response.data)
                    }
                  }]*/
                })
              }
              this.preventBind = true
              this.load()
              this.$nextTick(() => {
                this.preventBind = false
              })
            })
            .catch(error => {
              console.log(error)
              this.loading = false
              this.alertApiError(error)
            })
      }
      submit()
    }
  }
}
</script>
